import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Container,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import LeafLoading from "../../../common/LeafLoading";
import {
  getUserByUID,
  setCustomerFormData,
  saveUser
} from "../../../store/reducer/Home";
import { useAuth } from "../../../hooks/useAuth";

const CustomerForm = () => {
  const { login,user } = useAuth();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [checked, setChecked] = useState(false);
  // const [coordinates, setCoordinates] = useState([]);

  
  const [formData, setFormData] = useState({
    fullName: "",
    lastName: "",
    email: "",
    phoneNo:"",
    address: "",
    zipCode: "",
    city: "",
    state: "",
    houseNoBuildingName:""
  });
  const { customerFormData,userData } = useSelector((state) => state.apiData);

  const [loadingLocation, setLoadingLocation] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const success = (position) => {
      const { latitude, longitude } = position.coords;
      let lat=[latitude, longitude];
      console.log('location?.state',location)
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDks7ClHsi1SxSTKHhODu56_3JoCPkpBMc`;

      axios
        .get(geocodeUrl)
        .then((response) => {
          setLoadingLocation(false);
          const addressFormated = response.data.results[0].formatted_address;
          const addressComponents = response.data.results[0].address_components;
          const cityComponent = addressComponents.find((component) =>
            component.types.includes("locality")
          );
          const stateComponent = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          );
          const zipCodeComponent = addressComponents.find((component) =>
            component.types.includes("postal_code")
          );

          setFormData({
            zipCode: zipCodeComponent?.long_name || "",
            state: stateComponent?.long_name || "",
            city: cityComponent?.long_name || "",
            address: addressFormated || "",
            coordinates:lat,
            phoneNo:location?.state||''
          });
        })
        .catch((error) => {
          setLoadingLocation(false);
          setError("Error fetching location.");
          console.error("Error fetching location:", error);
        });
    };

    const errorCallback = (error) => {
      setLoadingLocation(false);
      setError("Unable to retrieve location.");
      console.error("Error getting location:", error);
    };

    if (navigator.geolocation) {
      setLoadingLocation(true);
      navigator.geolocation.getCurrentPosition(success, errorCallback);
    } else {
      setLoadingLocation(false);
      setError("Geolocation is not supported by this browser.");
    }
  }, []);

//   useEffect(() => {
// console.log('userData---fetch',userData)
//   }, [dispatch,userData]); 

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeNumber = (event) => {
    const value = event.target.value.replace(/\D/, ""); // Allow only numbers

    setFormData({
      ...formData,
      [event.target.name]: value,
    });
    // setMobile(value);
  };

  

  const handleSubmit = (event) => {
    event.preventDefault();

    if(!formData.fullName||!formData.email||!formData.address||!formData.zipCode||!formData.city||!formData.state){
      return window.alert('Please enter below details!')
     }

    if(!formData.phoneNo){
      return window.alert('Please enter mobile number!')
     }else if(formData?.phoneNo?.length<10){
      return window.alert('Please enter valied mobile number!')
     }

     setLoadingLocation(true);
    // Handle form submission here (e.g., send data to server)
    dispatch(setCustomerFormData(formData));
    dispatch(saveUser(formData))
    .then((res) => {
      if(res?.payload?.code==200){
        setLoadingLocation(false);
        navigate("/orderSummary");
        dispatch(getUserByUID(res?.payload?.result?.userID))
      .then((res) => {
        if (res.payload.code == 200) {
          login(res?.payload?.result);
        } else if (res.payload.code == 401) {
          setLoadingLocation(false);
        }
      })
      .catch((error) => {
        setLoadingLocation(false);
        console.error("Error fetching location:", error);
      });

      }else if(res?.payload?.code==401){
        setLoadingLocation(false);
      }

    })
    .catch((error) => {
      setLoadingLocation(false);
      console.error("Error fetching location:", error);
    });

    // navigate("/orderSummary");
  };


  return (
    <Container className="container" component="main" maxWidth="xs">
      {loadingLocation && <LeafLoading />}
      <Typography variant="h5" align="center" gutterBottom>
        Enter your delivery information
      </Typography>
    <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="fullName"
              label="Name"
              name="fullName"
              autoComplete="given-name"
              value={formData.fullName}
              onChange={handleChange}
              style={{ backgroundColor: "#fff" }}
            />
          </Grid>

          <Grid item xs={2}>
          <span className="countrycode">+91</span>
          </Grid>
          <Grid item xs={10}>

        
            <TextField
              required
              fullWidth
              id="phoneNo"
              label="Phone Number"
              name="phoneNo"
              type="phone"
              slotProps={{
                inputProps: { 
                    max: 10, min: 10 
                }
            }}
            inputProps={{maxLength: 10}}
            className="inputphone"
              autoComplete="tel"
              value={formData.phoneNo}
              onChange={handleChangeNumber}
              style={{ backgroundColor: "#fff"}}
            />

          </Grid>


          <Grid item xs={12}>
            <TextField
              fullWidth
              type="email" 
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleChange}
              style={{ backgroundColor: "#fff" }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="houseNoBuildingName"
              label="Society / House"
              name="houseNoBuildingName"
              value={formData.houseNoBuildingName}
              onChange={handleChange}
              style={{ backgroundColor: "#fff" }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="flatNo"
              label="Flat Number"
              name="flatNo"
              autoComplete="flatNo"
              value={formData.flatNo}
              onChange={handleChange}
              style={{ backgroundColor: "#fff" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="state"
              label="State"
              name="state"
              autoComplete="state"
              value={formData.state}
              onChange={handleChange}
              style={{ backgroundColor: "#fff" }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="city"
              label="City"
              name="city"
              autoComplete="city"
              value={formData.city}
              onChange={handleChange}
              style={{ backgroundColor: "#fff" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="zipCode"
              label="Pin Code"
              name="zipCode"
              autoComplete="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
              style={{ backgroundColor: "#fff" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="address"
              label="Shipping Address"
              name="address"
              autoComplete="shipping"
              style={{ backgroundColor: "#fff" }}
              value={formData.address}
              multiline
              rows={4}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <FormControlLabel
          control={
            <Checkbox checked={true} onChange={() => setChecked(true)} />
          }
          label="COD: Pay after delivery "
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          style={{ backgroundColor: "#2f7d31" }}
          sx={{ mt: 3, mb: 2 }}
         onClick={handleSubmit}
        >
          SAVE & NEXT
        </Button>
      </form>
    </Container>
  );
};

export default CustomerForm;
