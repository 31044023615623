import React from 'react';
import PrivacyPolicy from '../../components/PrivacyPolicy';

function Policy() {
  return (
    <div>
      <PrivacyPolicy />
    </div>
  );
}

export default Policy;
