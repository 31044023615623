import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto', fontFamily: 'Arial, sans-serif' }}>
      <h1>Privacy Policy for Treelix</h1>
      <p><strong>Effective Date:</strong> 23-07-2024</p>

      <h2>1. Introduction</h2>
      <p>
        At Treelix, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use Treelix, available on Google Play. By using the App, you agree to the practices described in this policy.
      </p>

      <h2>2. Information We Collect</h2>
      <p>We may collect the following types of information when you use the App:</p>
      <ul>
        <li><strong>Personal Information:</strong> Information that you provide directly, such as your name, email address, and any other details you submit.</li>
        <li><strong>Device Information:</strong> Data related to your device, including your IP address, device model, operating system, and unique device identifiers.</li>
        <li><strong>Usage Data:</strong> Information about how you use the App, including logs, activity data, and analytics (e.g., how long you use the App, features accessed, etc.).</li>
        <li><strong>Location Data:</strong> If enabled, we may collect your location information via GPS, Wi-Fi, or other location-tracking technologies.</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <p>We may use the information we collect for the following purposes:</p>
      <ul>
        <li>To provide, operate, and improve the App's features and services.</li>
        <li>To personalize your experience and tailor content based on your preferences.</li>
        <li>To communicate with you, including sending notifications or updates related to the App.</li>
        <li>To analyze app usage and conduct analytics to improve the functionality of the App.</li>
      </ul>

      <h2>4. Sharing Your Information</h2>
      <p>We may share your information in the following circumstances:</p>
      <ul>
        <li><strong>Third-Party Services:</strong> We may share your information with third-party service providers who help operate the App (e.g., cloud storage, analytics, advertising partners).</li>
        <li><strong>Legal Requirements:</strong> If required by law, we may disclose your information in response to legal requests such as a subpoena or court order.</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred to the acquiring entity.</li>
      </ul>

      <h2>5. Data Retention</h2>
      <p>
        We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
      </p>

      <h2>6. Security</h2>
      <p>
        We take reasonable measures to protect the information we collect from unauthorized access, alteration, or destruction. However, no data transmission over the internet can be guaranteed to be 100% secure, and we cannot ensure the absolute security of your data.
      </p>

      <h2>7. Your Choices and Rights</h2>
      <p>You have the following rights regarding your personal information:</p>
      <ul>
        <li><strong>Access and Correction:</strong> You can access and update your personal information in the App's settings.</li>
        <li><strong>Opt-out of Data Collection:</strong> You may opt-out of location tracking or other data collection by adjusting your device settings.</li>
        <li><strong>Delete Account:</strong> You may request to delete your account and personal information by contacting us at aparna@treelix.in.</li>
      </ul>

      <h2>8. Children's Privacy</h2>
      <p>
        The App is not intended for children under the age of 13. We do not knowingly collect personal information from children. If we learn that we have collected personal information from a child under 13, we will take steps to delete that information.
      </p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be reflected by an updated "Effective Date" at the top of this page. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
      </p>
      <p>
        <p><b>Treelix</b></p>
        <p><b>Email: aparna@treelix.in </b></p>
        <p><b>Address: Treelix Agritech & industries PVT LTD, Hinjewadi phase 1 411057.</b></p>
        
        
      </p>
    </div>
  );
};

export default PrivacyPolicy;
